// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.gform_wrapper ul {
  padding-left: 0;
  list-style: none
}

.gform_wrapper li {
  margin-bottom: 15px
}

.gform_wrapper form {
  margin-bottom: 0
}

.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: #a94442
}

.ginput_container input,
.ginput_container select,
.ginput_container textarea {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  color: #ccc;
  letter-spacing: normal;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eee;
  border-color: #ddd;
  height: 40px;
  color: #333
}

.ginput_container input:focus,
.ginput_container select:focus,
.ginput_container textarea:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6)
}

.ginput_container input::-moz-placeholder,
.ginput_container select::-moz-placeholder,
.ginput_container textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
  filter: alpha(opacity=100)
}

.ginput_container input:-ms-input-placeholder,
.ginput_container select:-ms-input-placeholder,
.ginput_container textarea:-ms-input-placeholder {
  color: #999
}

.ginput_container input::-webkit-input-placeholder,
.ginput_container select::-webkit-input-placeholder,
.ginput_container textarea::-webkit-input-placeholder {
  color: #999
}

.ginput_container input[disabled],
.ginput_container select[disabled],
.ginput_container textarea[disabled],
.ginput_container input[readonly],
.ginput_container select[readonly],
.ginput_container textarea[readonly],
fieldset[disabled] .ginput_container input,
fieldset[disabled] .ginput_container select,
fieldset[disabled] .ginput_container textarea {
  background-color: #eee;
  opacity: 1;
  filter: alpha(opacity=100)
}

.ginput_container input[disabled],
.ginput_container select[disabled],
.ginput_container textarea[disabled],
fieldset[disabled] .ginput_container input,
fieldset[disabled] .ginput_container select,
fieldset[disabled] .ginput_container textarea {
  cursor: not-allowed
}

textarea.ginput_container input,
textarea.ginput_container select,
textarea.ginput_container textarea {
  height: auto
}

.ginput_container textarea {
  height: auto
}

.gform_button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  background: #d06618;
  border: none;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  color: #fff;
  background-color: #0ea9c3;
  border-color: #0c94ab;
  height: 40px
}

.gform_button:focus,
.gform_button:active:focus,
.gform_button.active:focus,
.gform_button.focus,
.gform_button:active.focus,
.gform_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.gform_button:hover,
.gform_button:focus,
.gform_button.focus {
  color: #333;
  text-decoration: none
}

.gform_button:active,
.gform_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.gform_button.disabled,
.gform_button[disabled],
fieldset[disabled] .gform_button {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none
}

.gform_button:hover,
.gform_button:focus,
.gform_button.focus,
.gform_button:active,
.gform_button.active,
.open>.dropdown-toggle.gform_button {
  color: #fff;
  background-color: #0b8093;
  border-color: #086372
}

.gform_button:active,
.gform_button.active,
.open>.dropdown-toggle.gform_button {
  background-image: none
}

.gform_button.disabled,
.gform_button[disabled],
fieldset[disabled] .gform_button,
.gform_button.disabled:hover,
.gform_button[disabled]:hover,
fieldset[disabled] .gform_button:hover,
.gform_button.disabled:focus,
.gform_button[disabled]:focus,
fieldset[disabled] .gform_button:focus,
.gform_button.disabled.focus,
.gform_button[disabled].focus,
fieldset[disabled] .gform_button.focus,
.gform_button.disabled:active,
.gform_button[disabled]:active,
fieldset[disabled] .gform_button:active,
.gform_button.disabled.active,
.gform_button[disabled].active,
fieldset[disabled] .gform_button.active {
  background-color: #0ea9c3;
  border-color: #0c94ab
}

.gform_button .badge {
  color: #0ea9c3;
  background-color: #fff
}

.gform_wrapper .gfield_error {
  position: relative;
  margin-bottom: 20px
}

.gform_wrapper .gfield_error .gfield_label {
  color: #d9534f
}

.gform_wrapper .gfield_error input,
.gform_wrapper .gfield_error select,
.gform_wrapper .gfield_error textarea {
  border-color: #d9534f;
  background-color: #f9e2e2;
  color: #0ea9c3
}

.gform_wrapper .gfield_error input:focus,
.gform_wrapper .gfield_error select:focus,
.gform_wrapper .gfield_error textarea:focus {
  border-color: #a94442;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(169, 68, 66, .6)
}

.validation_error {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442
}

.validation_error h4 {
  margin-top: 0;
  color: inherit
}

.validation_error .alert-link {
  font-weight: 700
}

.validation_error>p,
.validation_error>ul {
  margin-bottom: 0
}

.validation_error>p+p {
  margin-top: 5px
}

.validation_error hr {
  border-top-color: #e4b9c0
}

.validation_error .alert-link {
  color: #843534
}

#gforms_confirmation_message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d
}

#gforms_confirmation_message h4 {
  margin-top: 0;
  color: inherit
}

#gforms_confirmation_message .alert-link {
  font-weight: 700
}

#gforms_confirmation_message>p,
#gforms_confirmation_message>ul {
  margin-bottom: 0
}

#gforms_confirmation_message>p+p {
  margin-top: 5px
}

#gforms_confirmation_message hr {
  border-top-color: #c9e2b3
}

#gforms_confirmation_message .alert-link {
  color: #2b542c
}

.ginput_container input[type=checkbox],
.ginput_container input[type=radio] {
  float: left;
  width: 40px;
  width: auto;
  height: auto
}

.ginput_container .gfield_checkbox label,
.ginput_container .gfield_radio label {
  margin-top: 0;
  margin-left: 10px;
  font-weight: 400
}

.ginput_container>ul>li {
  margin: 0;
  height: 25px
}

.field-weight-normal>label {
  font-weight: 400
}

.field-margin-bottom>label {
  margin-bottom: 20px
}

.gfield_description.validation_message {
  font-size: 12px;
  position: absolute!important;
  right: 10px;
  top: 0;
  background-color: #0ea9c3;
  padding: 0 10px;
  color: rgba(255, 255, 255, .8)
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  display: none!important;
  left: -9000px;
  position: absolute!important
}

.gform_wrapper span.gfield_required {
  color: red
}

.gf_progressbar {
  background-color: #eee
}

.gf_progressbar_percentage {
  background-color: #0ea9c3;
  text-align: center;
  color: white;
  line-height: 25px
}

h3.gf_progressbar_title {
  margin-bottom: 5px
}

.gform-line-top {
  border-top: 1px solid #eee;
  padding-top: 20px
}

.kill-pad {
  padding: 0
}

.fl {
  padding-right: 10px
}

@media(max-width:768px) {
  .fl {
    padding-right: 0
  }
}

.fr {
  padding-left: 10px
}

@media(max-width:768px) {
  .fr {
    padding-left: 0
  }
}

.hide-label>label {
  display: none
}

.gform_wrapper .gform_ajax_spinner {
  padding-left: 10px
}

.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute,
.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
  width: 70px;
  vertical-align: top;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1
}

.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
  margin-right: 12px
}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
  vertical-align: top;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1
}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
  margin-right: 6px
}

.gform_wrapper .gfield_time_ampm {
  vertical-align: top;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1
}

.gform_wrapper .gfield_time_ampm select {
  width: 60px!important
}

.gform_wrapper .gfield_time_hour input,
.gform_wrapper .gfield_time_minute input,
.gform_wrapper .gfield_date_month input,
.gform_wrapper .gfield_date_day input,
.gform_wrapper .gfield_date_year input {
  width: 70%!important
}

.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
  width: 50px;
  float: left
}

.gform_wrapper .gfield_date_month input,
.gform_wrapper .gfield_date_day input,
.gform_wrapper .gfield_date_year input {
  width: 85%!important
}

.gform_wrapper .field_hover {
  border: 1px dashed #2175a9;
  cursor: pointer
}

.gform_wrapper .field_selected {
  background-color: #dfefff;
  border: 1px solid #c2d7ef
}

.gform_wrapper .field_name_first,
.gform_wrapper .field_name_last {
  width: 50%;
  float: left
}

.gform_wrapper .ginput_complex {
  overflow: hidden
}

.gform_wrapper .ginput_left input:focus+label,
.gform_wrapper .ginput_right input:focus+label,
.gform_wrapper .ginput_full input:focus+label {
  font-weight: 700
}

.gform_wrapper .field_name_first input,
.gform_wrapper .ginput_complex .ginput_left input {
  width: 100%!important
}

.gform_wrapper .field_name_last input {
  width: 100%!important
}

.gform_wrapper .datepicker {
  width: 100px!important
}

.gform_wrapper .ginput_complex .ginput_left {
  width: 50%;
  float: left
}

.gform_wrapper .ginput_complex .ginput_right {
  width: 49%;
  float: right
}

.gform_wrapper .gfield_error .ginput_complex .ginput_left,
.gform_wrapper .gfield_error .ginput_complex .ginput_right {
  width: 49%
}

.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=text],
.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=url],
.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=email],
.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=tel],
.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=number],
.gform_wrapper .gfield_error .ginput_complex .ginput_left input[type=password] {
  width: 100%!important
}

.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=text],
.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=url],
.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=email],
.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=tel],
.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=number],
.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=password] {
  width: 100%!important
}

.gform_wrapper .ginput_complex input[type=text],
.gform_wrapper .ginput_complex input[type=url],
.gform_wrapper .ginput_complex input[type=email],
.gform_wrapper .ginput_complex input[type=tel],
.gform_wrapper .ginput_complex input[type=number],
.gform_wrapper .ginput_complex input[type=password],
.gform_wrapper .ginput_complex select {
  width: 100%!important
}

.gform_wrapper .ginput_complex .ginput_right input[type=text],
.gform_wrapper .ginput_complex .ginput_right input[type=url],
.gform_wrapper .ginput_complex .ginput_right input[type=email],
.gform_wrapper .ginput_complex .ginput_right input[type=tel],
.gform_wrapper .ginput_complex .ginput_right input[type=number],
.gform_wrapper .ginput_complex .ginput_right input[type=password],
.gform_wrapper .ginput_complex .ginput_right select {
  width: 100%!important
}

.gform_wrapper .ginput_complex label,
.gform_wrapper .gfield_time_hour label,
.gform_wrapper .gfield_time_minute label,
.gform_wrapper .gfield_date_month label,
.gform_wrapper .gfield_date_day label,
.gform_wrapper .gfield_date_year label,
.gform_wrapper .instruction,
.gform_wrapper .gfield_description {
  display: block;
  margin: 3px 0;
  font-size: 11px;
  letter-spacing: .5pt
}

.gform_wrapper .ginput_complex .name_prefix {
  float: left;
  width: 30px!important;
  margin-right: 14px
}

.gform_wrapper .ginput_complex .name_suffix {
  float: left;
  width: 30px!important
}

.gform_wrapper .ginput_complex .name_first,
.gform_wrapper .ginput_complex .name_last {
  float: left;
  width: 49%;
  margin-right: 2%
}
.gform_wrapper .ginput_complex .name_last {
  margin-right: 0;
}

.gform_wrapper .top_label .gfield_label {
  margin: 10px 0 4px;
  font-weight: 700;
  display: -moz-inline-stack;
  display: inline-block;
  line-height: 1.3em;
  clear: both
}

.gform_wrapper .left_label .gfield_label {
  float: left;
  margin: 0 15px 0 0;
  width: 29%;
  font-weight: 700
}

.gform_wrapper .right_label .gfield_label {
  float: left;
  margin: 0 15px 0 0;
  width: 29%;
  font-weight: 700;
  text-align: right
}

.gform_wrapper .left_label ul.gfield_checkbox,
.gform_wrapper .right_label ul.gfield_checkbox,
.gform_wrapper .left_label ul.gfield_radio,
.gform_wrapper .right_label ul.gfield_radio {
  margin-left: 32%;
  overflow: hidden
}

.gform_wrapper .right_label div.ginput_complex,
.gform_wrapper .left_label div.ginput_complex {
  width: 64%
}

.gform_wrapper h2.gsection_title {
  margin: 0!important;
  padding: 0!important;
  letter-spacing: normal!important
}

.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
  font-weight: 700;
  font-size: 1.3em
}

.gform_wrapper h3.gform_title {
  letter-spacing: normal!important;
  margin: 10px 0 6px
}

.gform_wrapper span.gform_description {
  font-weight: 400
}

.gform_wrapper h2.gsection_title,
.gform_wrapper .gsection_description,
.gform_wrapper h3.gform_title {
  width: 100%
}

.gform_wrapper .gsection {
  border-bottom: 1px dotted #CCC;
  padding: 0 0 8px;
  margin: 16px 0;
  clear: both
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox],
.gform_wrapper ul.gfield_radio li input[type=radio] {
  width: auto!important
}

.gform_wrapper .ginput_complex .ginput_left,
.gform_wrapper .ginput_complex .ginput_right,
.gform_wrapper .ginput_complex .ginput_full {
  min-height: 43px;
  display: block;
  overflow: hidden
}

.gform_wrapper .ginput_complex .ginput_full input[type=text],
.gform_wrapper .ginput_complex .ginput_full input[type=url],
.gform_wrapper .ginput_complex .ginput_full input[type=email],
.gform_wrapper .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .ginput_complex .ginput_full input[type=number],
.gform_wrapper .ginput_complex .ginput_full input[type=password] {
  width: 100%!important
}

.gform_wrapper label {
  float: none;
  display: block;
  padding: 10px 0 3px
}

.gform_wrapper .textarea {
  float: none;
  display: block
}

.gform_wrapper .gfield_radio input {
  float: left
}

.gform_wrapper .gfield_radio label {
  padding: 0 0 0 15px
}

.gform_footer {
  display: inline-block;
  clear: both;
  margin-bottom: 30px
}
