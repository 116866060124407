.sidebar {
  h3 {
    font-family: 'allerbold', Georgia, serif;
    font-size: 24px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #016377;
  }
  h2 {
    color: #016377;
    margin-top: 0;
  }

  .gform_wrapper {
    ul {
      padding-left: 0;

      li {
        &::before {
          display: none;
          margin-left: 0;
        }
      }
    }

    .gform_footer {
      .gform_button {
        margin-top: 15px;
        @extend .btn-secondary;
        @extend .btn;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        display: block;
        line-height: 1.7em;
        height: auto;
        width: 100%;
      }
      @media (min-width: $screen-sm-min) {
        width: 100%;
      }
    }
  }
}

.sidebar-block {
  margin-bottom: 30px;

  ul {
    list-style: none;
    padding-left: 1.3em;
    margin: 0;

    li {
      &:before {
        content: "-";
        display: inline-block;
        margin-left: -1.3em;
        /* same as padding-left set on li */
        width: 1.3em;
        /* same as padding-left set on li */
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  .table-responsive {
    font-size: 13px;
  }
}


/**
 * Image Tile
 */
 .promo-image {
  text-align: center;
  position: relative;
  font-family: $brand-primary-font;
  border-radius: 0;
  background: $brand-primary;
  overflow: hidden;

  @media (max-width: $screen-lg-min) {
    max-width: 400px; // margin-left: auto;

    // margin-right: auto;
    img {
      width: 100%;
    }
  }

  figure {
    text-align: center;

    img {
      max-width: 100%;
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }

  &:hover figure img {
    opacity: 0.85;
  }

  aside {
    padding: 10px;
    background: rgba($brand-black, 0.7);
    color: $brand-white;
    text-align: left;

    @media (max-width: $screen-lg-min) {
      opacity: 1 !important;
    }

    h2 {
      font-size: 21px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 5px;
      margin-bottom: 0;
      color: $brand-white;
      text-transform: none;
      font-family: $brand-primary-font-bold;
    }

    p {
      text-transform: none;
      font-family: $brand-primary-font;
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  a {
    text-decoration: none;
    text-align: right;

    .link {
      @include transition (0.5s, ease);
      color: $brand-white;
      font-weight: 500;
      text-transform: none;
      font-size: 14px;
    }

    &:hover {
      .link {}
    }
  }

  .link {
    padding: 0.5em 1em;
    margin-bottom: 0;
    color: $brand-white;
    font-family: $brand-primary-font-bold;
    text-transform: uppercase;
    font-size: 13px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: $screen-lg-min) {
  .promo-image {
    img {
      width: 100%;
    }

    aside {
      position: absolute;
      bottom: 38px;
      left: 0;
      width: 100%;
    }
  }
}
/**
* Promo BUttons
*/
.button-promo {
  .btn {
    text-transform: uppercase;
    font-family: $brand-primary-font-bold;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

/**
 * sidebar text block
 */
.sidebar-txt-block {
  .gfield_required {
    display: none;
  }
}

.side-nav {
  width: 100%;
  float: left;
  padding-bottom: 40px;

  ul {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      float: left;
      font-family: $brand-primary-font;
      font-size: $font-size-large + 1;
      color: $brand-secondary;
      list-style: none;
      line-height: 1.5em;
      padding: 10px 0;
      margin: 0;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $brand-secondary;

        &.active,
        &:hover {
          color: $brand-success;
          text-decoration: none;
        }

        &.active {
          font-weight: bold;
        }
      }
    }

    ul {
      padding-left: 15px;
      padding-top: 10px;

      li {
        font-size: $font-size-large - 4;
        line-height: 1.2em;
        padding: 8px 0;
      }
    }
  }

  @media (min-width: $screen-md-max) and (max-width: $screen-xs-max) {
    ul {
      li {
        font-size: $font-size-base + 1;
        line-height: 1.5em;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    ul {
      li {
        font-size: $font-size-base;
        line-height: 1.2em;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    ul {
      li {
        font-size: $font-size-small - 1;
        line-height: 1em;
      }
    }
  }
}

.side-nav {
  width: 100%;
  float: left;
  padding-bottom: 40px;
  ul {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0 0 0 30px;
    position: relative;
    li {
      width: 100%;
      float: left;
      font-family: $font-family-base;
      font-size: $font-size-large + 1;
      color: $brand-secondary;
      list-style: none;
      line-height: 1.5em;
      padding: 5px 0;
      margin: 0;
      &:last-child {
        border-bottom: none;
      }
      a {
        color: #008da9;
        &.active,
        &:hover {
          color: $brand-success;
          text-decoration: none;
        }
        &.active {
          font-weight: bold;
        }
      }
      &::before {
        content: " ";
        color: #333;
        font-weight: 400;
      }
    }
    ul {
      padding-left: 10px;
      padding-top: 10px;
      li {
        font-size: $font-size-large - 2;
        line-height: 1.2em;
        padding: 8px 0;
      }
    }
    &.collapsibleList {
      .page_item_has_children {
        ul {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          li {
            ul {
              opacity: 0;
              visibility: hidden;
              position: absolute;
            }
          }
        }
        &:before {
          content: "+ ";
          cursor: pointer;
        }
        &:hover {
          ul {
            opacity: 1;
            position: relative;
            visibility: visible;
            -webkit-transition: opacity 600ms, visibility 600ms ease-in-out;
            transition: opacity 600ms, visibility 600ms ease-in-out;
            li {
              ul {
                opacity: 0;
                visibility: hidden;
                position: absolute;
              }
              &:hover {
                ul {
                  opacity: 1;
                  visibility: visible;
                  position: relative;
                  -webkit-transition: opacity 600ms, visibility 600ms ease-in-out;
                  transition: opacity 600ms, visibility 600ms ease-in-out;
                }
              }
            }
          }
          &::before {
            content: "- ";
          }
        }
      }
    }
  }
  @media (min-width: $screen-md-max) and (max-width: $screen-xs-max) {
    ul {
      li {
        font-size: $font-size-base + 1;
        line-height: 1.5em;
      }
    }
  }
  @media (max-width: $screen-md-max) {
    ul {
      li {
        font-size: $font-size-base;
        line-height: 1.2em;
      }
    }
  }
  @media (max-width: $screen-sm-max) {
    ul {
      li {
        font-size: $font-size-small - 1;
        line-height: 1em;
      }
    }
  }
}
