.footer-main {
  width: 100%;
  float: left;
}

.footer-bar {
  padding: 20px 0 25px;
  background: $dark-blue2;
  border-top: 6px solid #016b81;
  border-bottom: 6px solid #016b81;
  text-align: center;
  @media (max-width: $screen-md-max3) {
    padding-bottom: 40px;
  }
  .footer-container {
    @extend .container;
  }
  .footer-row {
    @include make-row();
  }
  .foot-logo {
    @include make-sm-column(2);
    img {
      max-width: 135px;
    }
  }
  .foot-text {
    @include make-sm-column(5);
    h2 {
      font-size: $font-size-h2 + 18;
      color: $white;
      padding-bottom: 12px;
    }
    p {
      font-family: $font-family-prnov-semibold;
      font-size: $font-size-base - 2;
      color: $white;
      line-height: $line-height-base - 16;
      text-align: right;
      padding: 0 11px 0 0;
    }
    .txt-sml {
      font-size: $font-size-base;
      color: $white;
      letter-spacing: 1px;
      line-height: $line-height-base - 12;
      padding: 0 0 13px 0;
    }
    aside {
      width: 100%;
      float: left;
    }
  }
  .newslater {
    @include make-sm-column(5);
    margin-top: 58px;
    @media (max-width: $screen-sm) {
      margin-top: 20px;
    }
    h5 {
      font-family: $font-family-allerbold;
      font-size: 16px;
      color: $white;
      display: inline-block;
    }
    .btn {
      width: 100px;
      height: 36px;
      font-family: $font-family-allerbold;
      font-size: $font-size-base - 4;
      color: $white;
      line-height: $line-height-base + 8;
      font-weight: normal;
      border: none;
      border-radius: 6px;
      top: 0;
      right: 0;
      padding: 0 20px;
      display: inline-block;
      margin-left: 20px;
      @include transition (0.5s, ease);
      &:hover {
        @include transition (0.5s, ease);
      }
      @media (max-width: $screen-sm) {
        display: block;
        margin: 10px auto;
      }
    }
  }
  .footer-giveonline {
    margin-top: 58px;
    @media (max-width: $screen-sm) {
      margin-top: 0;
    }
    .menu {
      padding-left: 0;
      display: inline-block;
      li {
        display: inline-block;
        font-size: 15px;
        margin-right: 10px;
        text-transform: uppercase;
        font-family: $font-family-allerbold;
        a {
          color: $white;
        }
      }
    }
  }
  .footer-give {
    background: #d1eaab none repeat scroll 0 0;
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
    height: 35px;
    line-height: 37px;
    margin: 0 0 0 10px;
    padding: 0 16px;
    &:hover {
      text-decoration: none;
      background: darken(#d1eaab, 10%);
    }
    @media (max-width: $screen-sm) {
      display: block;
      margin: 10px auto;
      width: 120px;
    }
  }
  .social-links {
    @include make-sm-column(12);
    ul {
      display: inline-block;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        float: none;
        font-size: 24px;
        line-height: 24px;
        margin: 0 10px;
        a {
          color: $white;
        }
      }
      @media (max-width: $screen-sm) {
        margin-top: 10px;
      }
    }
  }
}

.footer-bottom {
  width: 100%;
  float: left;
  overflow: hidden;
  padding: 25px 0 40px 0;
  .copy-right-p {
    display: inline-block;
    width: 100%;
    p {
      color: #0187a3;
      font-size: 14px;
      line-height: 24px;
      margin: 40px 0 0;
      padding: 0;
      text-align: center;
    }
  }
}

.footer-links {
  width: 104%;
  float: left;
  text-align: center;
  padding: 0 0 5px 0;
  margin: 0 -2%;
  aside {
    display: inline-block;
    vertical-align: middle;
    padding: 18px 0;
    ul {
      float: left;
      list-style: none;
      margin: 0 0 0 -10px;
      padding: 0;
      li {
        float: none;
        display: inline-block;
        font-size: $font-size-base - 2;
        line-height: $line-height-base - 14;
        letter-spacing: 1px;
        margin: 0 9px;
        font-weight: 600;
        padding: 0;
        @media (max-width: $screen-sm) {
          display: block;
          margin-bottom: 10px;
        }
        a {
          color: #01819E;
          &:hover,
          &.active {
            color: #013e4a;
          }
        }
      }
    }
  }
  @media (min-width: $screen-md-max1) and (max-width: $screen-md-max2) {
    aside {
      ul {
        li {
          font-size: $font-size-base - 3;
          margin: 0 5px;
        }
      }
    }
  }
}

footer .copy-right {
  float: left;
  text-align: center;
  width: 100%;
  p {
    color: #0187a3;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 0;
    span {
      color: #ccc;
      margin: 0 12px;
    }
  }
}
