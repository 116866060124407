.main {
  margin-top: 25px;
  margin-bottom: 25px;
}


/*= Body
=======================================================*/

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  color: $text-color;
  line-height: $line-height-base;
  background: $body-bg;
  margin: 0;
  padding: 0;
}

@media (max-width: $screen-xs-max) {
  body {
    font-size: 15px;
    line-height: 28px;
  }
}

pre {
  word-break: normal;
  word-wrap: normal;
}

img {
  width: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
  line-height: .5em;
}


/*= Links
=======================================================*/

a {
  color: $link-color;
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  outline: 0;
  &:hover,
  &:active,
  &:focus {
    color: $link-hover-color;
    text-decoration: none;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    outline: 0;
  }
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    outline: 0;
    text-decoration: none;
  }
}


/*= Headings: P, H1, H2, H3, H4, H5, H6
=======================================================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-allerbold;
  font-weight: normal;
  font-size: $font-size-large;
  color: $text-color;
  line-height: $line-height-base - 4;
  letter-spacing: normal;
  padding: 0 0 10px 0;
  margin: 0;
}

h1 {
  font-size: $font-size-h1;
  color: $dark-blue;
  line-height: $line-height-base + 4;
}

h2 {
  font-size: $font-size-h2;
  line-height: $line-height-base + 4;
}

h3 {
  font-size: $font-size-h3;
  line-height: $line-height-base;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

p {
  padding: 0 0 20px 0;
  margin: 0;
}

em {
  font-style: normal;
}

strong {
  font-family: $font-family-prnov-bold;
}

.bold {
  font-family: $font-family-prnov-bold;
}

.upper {
  text-transform: uppercase
}

@media (max-width: 767px) {
  h1 {
    font-size: $font-size-h1 - 6;
  }
  h2 {
    font-size: $font-size-h2 - 2;
  }
  h3 {
    font-size: $font-size-h3 - 2;
  }
}


/* #Input Styles
================================================= */

/*= Common styles
=======================================================*/

.container {
  width: 100%;
  max-width: 1120px;
  padding: 0 20px;
}

.page-title {
  padding-left: 15px;
}

.col-xs-4 {
  width: 33.33%;
  padding: 0;
}

@media (min-width: $screen-lg) {
  .container {
    width: 100%;
    max-width: 1120px;
    padding: 0 20px;
  }
  .col-xs-4 {
    width: 33.33%;
    padding: 0;
  }
}

@media (min-width: $screen-sm) and (max-width: $screen-md-max3) {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }
  .col-xs-4 {
    width: 50%;
    padding: 0;
  }
}

@media (max-width: $screen-xs-max) {
  .container {
    width: 100%;
    margin: 0;
    padding: 0 20px;
  }
  .col-xs-4 {
    width: 100%;
    *width: 99.99%;
    padding: 0;
  }
}


/* verticalalign middle */

.outer-bg {
  width: 100%;
  height: 100%;
  float: none;
  display: table;
  margin: 0 auto
}

.inner-bg {
  width: 100%;
  height: 100%;
  float: none;
  display: table-cell;
  vertical-align: middle;
  text-align: center
}


/*
 2.) Home Page
----------------------------------------*/

.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-fade .carousel-control {
  z-index: 2;
}

.collapse img {
  display: inline-block;
}


/*
 2.2) Banner
----------------------------------------*/

.banner-main {
  width: 100%;
  float: left;
  position: relative;
  @media (min-width: $screen-md) {
    top: 0px;
    margin-bottom: 0px;
    z-index: 14;
  }
  &.inner-banner {
    img {
      width: 100%;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      width: 100%;
      float: left;
      margin: 0;
      padding: 0;
    }
  }
  figure {
    width: 100%;
    float: left;
    text-align: center;
    overflow: hidden;
    img {
      width: 100%;
      min-width: 1600px;
      margin: 0 -800px;
    }
    @media (max-width: $screen-xs-max) {
      img {
        min-width: 1500px;
        margin: 0 -750px;
      }
    }
  }
  aside {
    width: 100%;
    max-width: 500px;
    min-height: 190px;
    float: left;
    text-align: left;
    background: rgba(245, 245, 245, .9);
    border-radius: 10px;
    padding: 20px 30px 0 30px;
    position: relative;
    h1 {
      width: 100%;
      float: left;
      padding: 0 0 15px 0;
    }
    p {
      font-size: $font-size-base + 1;
      line-height: $line-height-base - 6;
      color: $gray-dark;
      padding: 0 0 20px 0;
    }
    .btn-out {
      width: 100%;
      float: left;
      margin: 0 0 -28px 0;
      position: relative;
      z-index: 10;
    }
    @media (max-width: $screen-xs-max) {
      max-width: 100%;
      text-align: center;
      padding: 20px 5%;
      h1 {
        font-size: $font-size-h1 - 10;
        line-height: $line-height-base;
      }
      p {
        font-size: $font-size-base - 2;
        line-height: $line-height-base - 8;
      }
      .btn-out {
        margin: 0;
      }
    }
  }
  .carousel-caption {
    width: 100%;
    float: left;
    position: absolute;
    bottom: 155px;
    left: 0;
    text-shadow: none;
    padding: 0;
    margin: 0;
    @media (max-width: $screen-lg-min) {
      padding-left: 100px;
    }
    @media (max-width: $screen-md-max3) {
      padding: 0 30px;
    }
    @media (max-width: $screen-xs-max) {
      padding: 0 5%;
      bottom: 90px;
    }
  }
  .carousel-indicators-bg {
    width: 100%;
    float: left;
    .carousel-indicators {
      width: 320px;
      float: left;
      position: relative;
      top: inherit;
      bottom: 0;
      left: 0;
      right: inherit;
      text-align: left;
      display: block;
      z-index: 50;
      padding: 0;
      margin: -180px 0 130px 220px;
      li {
        width: 50px;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        background: #ffffff;
        border: none;
        border-radius: 0;
        position: relative;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        margin: 0 4px;
        padding: 3px;
        img {
          width: 100%;
          float: left;
        }
        &.active {
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          background: $dark-blue2 !important;
          margin-top: -4px;
        }
      }
      @media (max-width: $screen-lg-min) {
        margin: -211px 0 130px 220px;
      }
      @media (max-width: $screen-xs-max) {
        width: 100%;
        text-align: center;
        margin: 0;
        li {
          width: 40px;
          height: 40px;
          padding: 3px;
          margin: 0px 3px;
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      position: absolute;
      bottom: 20px;
      left: 0;
    }
  }
  .navigation {
    width: 100%;
    float: left;
    a {
      width: 26px;
      height: 36px;
      float: left;
      cursor: pointer;
      opacity: 1;
      /*background-color: rgba(0, 0, 0, .1);*/
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 3px;
      text-indent: -9999px;
      position: relative;
      margin-top: -20px;
      position: absolute;
      top: 47%;
      z-index: 10;
      &:hover {
        opacity: 1;
      }
      &.previous {
        left: 30px;
        background-image: url(../images/arrow-prev01.png);
      }
      &.next {
        right: 30px;
        background-image: url(../images/arrow-next01.png);
      }
    }
    @media (max-width: $screen-lg-min) {
      a {
        &.previous {
          left: 10px;
        }
        &.next {
          right: 10px;
        }
      }
    }
    @media (max-width: $screen-xs-maxnew) {
      a {
        &.previous {
          left: 5px;
        }
        &.next {
          right: 5px;
        }
      }
    }
  }
}


/*
 2.3) Content Main
----------------------------------------*/

.content-main {
  float: left;
  background: url(../images/bg01.jpg) no-repeat top center;
  padding: 60px 0;
  @media (max-width: $screen-xs-max) {
    padding: 35px 0;
  }
}


/*
 2.3.1) Top Boxes
----------------------------------------*/

.section-feature {
  width: 100%;
  float: left;
  margin: -125px 0 0 0;
  padding-bottom: 35px;
  position: relative;
  z-index: 16;
  h2 {
    font-size: $font-size-h2 + 2;
    color: $white;
    letter-spacing: -1px;
    line-height: $line-height-base + 4;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    padding: 10px 0;
  }
  p {
    font-size: $font-size-base + 2;
    color: $white;
    line-height: normal;
    padding: 0;
  }
  figure {
    width: 100%;
    float: left;
    img {
      width: 100%;
    }
  }
  article {
    width: 33.3%;
    float: left;
    position: relative;
    max-height: 140px;
    overflow: hidden;
    &:hover {
      h2 {
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        margin: -15px 0 0 0;
      }
    }
  }
  aside {
    width: 100%;
    height: 100%;
    float: left;
    background: rgba(55, 1, 165, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    &.aside-1 {
      background: rgba(18, 203, 205, 0.5);
    }
    &.aside-2 {
      background: rgba(255, 102, 0, 0.6);
    }
  }
  .article-out {
    width: 100.46%;
    float: left;
    position: relative;
    margin-right: -0.46%
  }
  @media (max-width: $screen-md-max3) {
    h2 {
      font-size: $font-size-h2 - 2;
      padding-bottom: 2px;
    }
    p {
      font-size: $font-size-base - 1;
    }
  }
  @media (max-width: $screen-xs-max) {
    margin: 0;
    padding: 0 0 40px 0;
    h2 {
      font-size: $font-size-h2;
      padding-bottom: 2px;
    }
    p {
      font-size: $font-size-base;
    }
    article {
      width: 100%;
      margin: 0 0 5px 0;
    }
    .article-out {
      width: 100%;
      max-width: 420px;
      float: none;
      margin: 0 auto;
    }
  }
}


/*
 2.3.2) Events
----------------------------------------*/

.section-1-bg {
  width: 100%;
  float: left;
  .section-1 {
    width: 100%;
    float: left;
  }
  @media (max-width: $screen-xs-max) {
    .section-1 {
      max-width: 420px;
      float: none;
      margin: 0 auto;
    }
  }
}

.home .section-1-bg {
  h3 {
    font-family: $font-family-allerbold;
    font-size: 24px;
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #016377;
    margin-bottom: 15px;
  }
}

.event-list {
  width: 100%;
  float: left;
  padding: 0 20px 0 0;
  &.unity {
    aside {
      padding: 0 0 0 40px;
      @media (max-width: $screen-sm) {
        padding: 0;
      }
    }
  }
  article {
    border-bottom: 2px solid #f1ede8;
    float: left;
    margin-bottom: 15px;
    padding: 0 0 15px;
    width: 100%;
    &:last-child {
      margin: 0;
      padding: 0;
      border: none;
    }
  }
  h4 {
    font-size: $font-size-h4 + 1;
    padding-bottom: 4px;
    font-family: $font-family-allerbold;
    font-weight: normal;
    a {
      color: #018ba7;
      &:hover {
        color: $dark-blue2;
      }
    }
  }
  p {
    font-size: $font-size-small;
    color: $gray-darker1;
    line-height: normal;
    padding-bottom: 4px;
    &:last-child {
      padding-bottom: 0;
    }
    a {
      font-family: $font-family-prnov-semibold;
      font-size: $font-size-small - 2;
      color: $brand-primary;
      &:hover {
        color: $gray-darker1;
      }
    }
  }
  .meta {
    font-size: $font-size-small - 2;
    line-height: 1.2em;
    padding-bottom: 5px;
    span {
      font-weight: bold;
    }
  }
  .date-box {
    width: 52px;
    height: 48px;
    float: left;
    font-size: $font-size-base + 14;
    color: $white;
    font-family: $font-family-prnov-regular;
    background: $dark-blue2;
    text-align: center;
    line-height: $line-height-base + 20;
    margin: 4px -52px 0 0;
    position: relative;
    &:before {
      width: 100%;
      height: 3px;
      float: left;
      content: "";
      background: $voilet-lev1;
      position: absolute;
      bottom: -4px;
      left: 0;
    }
  }
  aside {
    width: 100%;
    float: left;
    padding: 0 0 0 0;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0 0 50px 0;
    h2 {
      font-size: $font-size-h2;
    }
  }
}


/*
 2.3.3) Media
----------------------------------------*/

.article-media {
  width: 100%;
  float: left;
  padding: 0 15px 0 0;
  .video {
    width: 100%;
    float: left;
  }
  .unity-videos {
    article {
      background: #f0f0f0;
      padding: 15px 10px 25px;
      aside {
        width: 100%;
        float: left;
        .txt-sml {
          font-family: $font-family-allerbold;
          color: #018ba7;
          text-transform: uppercase;
          font-size: 15px;
        }
        h3 {
          width: 100%;
          float: left;
          line-height: $line-height-base - 4;
          padding-bottom: 0;
          margin-bottom: 0;
          font-family: $font-family-prnov-regular;
          text-transform: none;
          a {
            color: #666666;
            &:hover {
              color: $dark-blue2;
            }
          }
          span {
            font-size: $font-size-base;
            color: $gray-lighter1;
            p{
              margin: 0;
              padding: 0;
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .unity-audio {
    display: inline-block;
    margin-top: 5px;
    h3{
      margin-bottom: 0px;
    }
    .listen-audio {
      margin-bottom: 30px;
    }
  }
  .video-play {
    float: left;
    margin: 3px 0 0;
    position: relative;
    width: 100%;
    .time {
      width: 100%;
      float: left;
      font-size: $font-size-base;
      color: $gray-lighter1;
      line-height: $line-height-base - 10;
      padding-bottom: 5px;
      span {
        font-size: $font-size-large;
        color: $gray-dark;
      }
    }
    .play-bar {
      width: 100%;
      float: left;
      padding: 3px 0 20px 55px;
      .progressbar {
        width: 100%;
        height: 3px;
        float: left;
        background: $gray-lighter2;
        position: relative;
        span {
          width: 40%;
          height: 3px;
          float: left;
          background: $gray-lighter3;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
        }
      }
    }
    .icon-play {
      width: 44px;
      height: 44px;
      float: left;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  article {
    width: 100%;
    float: left;
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .unity-videos {
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
  @media (max-width: $screen-md-max3) {
    padding: 0 0 40px 0;
  }
}


/*
 2.3.4) Testimonials
----------------------------------------*/

.article-testimonial {
  width: 100%;
  float: left;
  padding: 30px 20px 0 10px;
  h3 {
    background: rgba(0, 0, 0, 0) url("../images/icon-testimonial01.png") no-repeat scroll 0 0;
    float: left;
    font-family: "fjordone", Georgia, "Times New Roman", Times, serif;
    font-size: 24px;
    margin: 0 0 30px;
    padding: 37px 0 0;
    position: relative;
    width: 260px;
    span {
      float: left;
      font-size: 38px;
      padding: 15px 25px 0 28px;
      text-align: right;
      width: 100%;
    }
    .icon-twitter {
      bottom: 33px;
      float: right;
      position: absolute;
      right: 0;
    }
    @media (min-width: 980px) and (max-width: 1079px) {
      .icon-twitter {
        width: 70px;
      }
    }
    @media (max-width: $screen-xs-max) {
      font-size: $font-size-h3 + 8;
      margin-bottom: 25px;
      span {
        font-size: $font-size-h3;
      }
      .icon-twitter {
        width: 70px;
      }
    }
  }
  p {
    width: 100%;
    float: left;
    font-family: $font-family-prnov-regular;
    font-size: 19px;
    line-height: $line-height-base + 7;
    letter-spacing: normal;
    padding: 0;
    &.name {
      text-align: right;
      font-family: "proxima_nova_rgbold", Arial, Helvetica, sans-serif;
      padding: 0;
      a {
        color: #333;
      }
    }
    @media (max-width: $screen-xs-max) {
      font-size: $font-size-base + 2;
      line-height: $line-height-base + 2;
    }
  }
  aside {
    width: 100%;
    float: left;
    padding: 0 18px;
  }
  @media (max-width: $screen-md-max3) {
    padding: 0;
  }
}

.inside {
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
  &.inside1 {
    h5 {
      background-color: #60b4c3;
    }
  }
  &.inside2 {
    h5 {
      background-color: #ee6f3e;
    }
  }
  h5 {
    background-color: #8ebd9d;
    color: #ffffff;
    font-family: "proxima_nova_ltbold", Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin: 0;
    padding: 5px 0 5px 10px;
  }
}

.give-online {
  background: rgba(0, 0, 0, 0) url("../images/give-online.jpg") no-repeat scroll left top;
  padding: 78px 10px 2px;
  @media (max-width: $screen-sm) {
    background-size: cover;
  }
  h4 {
    color: #fff;
    font-weight: bold;
  }
}

.minister {
  background: rgba(0, 0, 0, 0) url("../images/ministerand-staff.jpg") no-repeat scroll left top;
  @media (max-width: $screen-sm) {
    background-size: cover;
  }
}

.youth {
  background: rgba(0, 0, 0, 0) url("../images/youth-ministery.jpg") no-repeat scroll left top;
  @media (max-width: $screen-sm) {
    background-size: cover;
  }
}

.p-area {
  display: inline-block;
  margin-top: 68px;
  width: 100%;
  @media (max-width: $screen-sm) {
    margin-top: 18px;
  }
}

.sidebar-block {
  display: inline-block;
  margin-bottom: 40px;
  width: 100%;
  @media (max-width: $screen-sm) {
    margin-top: 30px;
  }
}

.side-nav {
  float: left;
  width: 100%;
  ul {
    padding: 0;
    li {
      border-bottom: 1px solid #dedede;
      color: #018aa6;
      font-family: "proxima_nova_rgregular", Arial, Helvetica, sans-serif;
      font-size: 21px;
      line-height: 25px;
      list-style: outside none none;
      margin: 0;
      padding: 10px 0;
      width: 100%;
      a {
        color: #333;
        &.active {
          font-family: $font-family-prnov-bold;
          color: #27afad;
        }
      }
    }
  }
}

.main {
  padding-right: 40px;
  @media (max-width: $screen-sm) {
    width: 100%;
    padding-right: 15px;
  }
  h1 {
    color: #308288;
    font-size: 40px;
    font-weight: bold;
    margin: 0 0 30px;
    @media (max-width: $screen-sm) {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.slides {
  padding: 0;
  list-style: none;
  &:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden;
  }
  img {
    display: block;
    width: 100%;
  }
}

.flexslider {
  position: relative;
  &:hover {
    .flex-prev {
      left: 5px;
      opacity: 0.8;
    }
    .flex-next {
      opacity: 0.8;
      right: 5px;
    }
  }
}

.flex-direction-nav {
  list-style: none;
  a {
    cursor: pointer;
    display: block;
    opacity: 0;
    position: absolute;
    text-indent: -9999px;
    top: 80%;
    z-index: 999;
    &.flex-prev {
      background: rgba(0, 0, 0, 0) url("../images/slide-left.png") repeat scroll 0 0;
      height: 23px;
      left: -20px;
      width: 23px;
    }
    &.flex-next {
      background: rgba(0, 0, 0, 0) url("../images/slide-right.png") repeat scroll 0 0;
      height: 23px;
      right: -20px;
      width: 23px;
    }
  }
}

.flex-control-nav {
  bottom: -40px;
  position: absolute;
  text-align: center;
  width: 100%;
  li {
    display: inline-block;
    margin: 0 6px;
    a {
      background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
      border-radius: 20px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
      cursor: pointer;
      display: none;
      height: 11px;
      text-indent: -9999px;
      width: 11px;
    }
  }
}

.slider-middle {
  background: rgba(11, 86, 100, 0.7) none repeat scroll 0 0;
  bottom: 20px;
  float: left;
  left: 49px;
  padding: 10px 30px 10px 20px;
  position: absolute;
  width: 86%;
  @media (max-width: $screen-sm) {
    width: auto;
    bottom: 0;
    right: 35px;
    left: 35px;
  }
  h2 {
    color: #fff;
    font-size: 22px;
    margin: 0;
    padding: 0;
  }
  p {
    color: #67d6d8;
    font-size: 18px;
    line-height: 22px;
    padding: 0;
    @media (max-width: $screen-sm) {
      font-size: 11px;
      line-height: 17px;
    }
  }
}

.Scientists-part {
  @media (max-width: $screen-sm) {
    width: 100%;
  }
}
