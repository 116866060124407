.site-header {
  margin-bottom: 30px;
  padding-top: 20px;
  .site-logo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
  }
  .site-slogan{
    text-align: center;
    font-size: 15px;
  }
}



.header-main {
  width: 100%;
  float: left;
  padding: 0 0 8px 0;
  @media (min-width: $screen-md) {
    position: relative;
    z-index: 15;
  }

  @media (max-width: $screen-md-max3) {
    padding: 0 0 20px 0;
  }

  @media (max-width: $screen-xs-max) {
    padding: 60px 0 15px 0;
  }

  .brand {
  width: 137px;
  float: left;
  margin: 75px -195px 0 0;
  position: relative;
  z-index: 10;

    img {
      max-width:100%;
    }

    @media (max-width: $screen-md-max3) {
      margin-top: 40px;
    }

    @media (max-width: $screen-xs-max) {
      margin-top: 0;
    }

  }

  .btns {
    float: right;
    margin: 0 0 0 40px;

    .btn {
      height: 35px;
      font-size: $font-size-small - 2;
      line-height: $line-height-base + 7;
      background: $green-light;
      padding: 0 20px;
      margin: 0 0 0 10px;

      &.btn-yellow {
        color: $orange-lev3;
        background: $green-yellow;

        &:hover {
          color: $white;
        }

      }

    }

    @media (max-width: $screen-xs-max) {
      width: 100%;
      background: #ecebeb;
      text-align: center;
      margin: 0;
      padding: 2px 0;
      position: absolute;
      top: 0;
      left: 0;

      .btn {
        margin: 7px;
      }

    }

  }

  .social-links {
    float: right;

    ul {
      li {
        display: inline-block;
        float: left;
        font-size: 24px;
        line-height: 38px;
        margin: 0 0 0 18px;
        padding: 0;
      }

    }

    @media (max-width: $screen-xs-max) {
      display: none;
    }

  }

  .top-bar {
    width: 100%;
    float: left;
    margin: 30px 0 0 0;

    @media (max-width: $screen-md-max3) {
      margin: 15px 0 0 0;
    }

    @media (max-width: $screen-xs-max) {
      margin: 0;
    }

  }

  .nav-bar {
    width: 100%;
    float: left;
    margin: 12px 0 0 0;

    nav {
      float: right;
      margin: 0;

      ul {
        float: right;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          height: 40px;
          float: left;
          font-family: $font-family-prnov-bold;
          font-size: $font-size-base - 1;
          color: $dark-blue2;
          text-transform: uppercase;
          letter-spacing: 1px;
          line-height: $line-height-base + 10;
          margin: 0 0 0 25px;
          position: relative;

          a {
            color: $dark-blue2;
            text-decoration: none;
          }

          &:hover, &.active {
            a {
              color: $orange-lev1;
            }

            ul {
              li {
                a {
                  color: $text-color;

                  &:hover {
                    color: $dark-blue2;
                  }

                }

                &.active {
                  a {
                    color: $dark-blue2;
                  }

                }

              }

            }

          }

          ul {
            width:245px;
            float:left;
            background:#FFFFFF;
            @include box-shadow(0, 15px, 15px, rgba(0, 0, 0, 0.3));
            position: absolute;
            top: 40px;
            left: -20px;
            z-index: 30;
            display: none;
            margin: 0;
            padding:5px 0 10px 0;

              li {
              width: 100%;
              height: auto;
              float: left;
              font-family: $font-family-prnov-regular;
              font-size: $font-size-base;
              color: $text-color;
              text-transform: none;
              letter-spacing: normal;
              line-height: $line-height-base - 10;
              margin: 0;

              a {
                width: 100%;
                float: left;
                color: $text-color;
                padding: 7px 20px;
              }

            }

          }

        }

      }

    }


    @media (min-width: $screen-md-max1) and (max-width: $screen-md-max2) {
      nav {
        ul {
          li {
            margin-left: 23px;
          }

        }

      }

    }

    @media (min-width: $screen-md-max1) {
      nav {
        display: block;
      }

    }


    @media (max-width: $screen-md-max3) {
      nav {
        width: 100%;
        background: $white;
        @include box-shadow(0, 15px, 15px, rgba(0, 0, 0, 0.3));
        position:absolute;
        top:160px;
        left:0;
        z-index: 1000;

        ul {
          width: 100%;
          float: left;
          padding: 10px 20px;

          li {
            width: 100%;
            height: auto;
            float: left;
            line-height: $line-height-base - 10;
            margin: 0;
            padding: 7px 0;

            .accord {
              width: 25px;
              height: 25px;
              float: left;
              position: absolute;
              top: 2px;
              right: 0;
              cursor: pointer;
              display: none;

              &:before {
                float: left;
                font-family:"FontAwesome", Arial, Helvetica, sans-serif;
                font-size: $font-size-base;
                content: "\f0d7";
                position: absolute;
                top: 4px;
                right: 0;
              }

              &.active {
                color: $orange-lev1;
              }

            }

            &:hover, &.active, {
              .accord {
                color: $orange-lev1;
              }

            }

            &.children {
              .accord {
                display: block;
              }

            }

            ul {
              width: 100%;
              float: left;
              display: none;
              position: relative;
              box-shadow: none;
              top: 0;
              left: 0;
              padding: 10px 0 0 0;

              li {
                padding: 0;
              }

            }

          }

        }

      }

    }

    @media (max-width: $screen-xs-max) {
      margin: 0;

      nav {
        top: 162px;
      }

    }

  }

  .menu-btn {
    width: 34px;
    height: 34px;
    float: right;
    padding: 4px 2px;
    margin: 25px 0 -25px 0;
    display: none;
    z-index: 100;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;

    span {
      width:100%;
      height:4px;
      float:left;
      background: $dark-blue2;
      border-radius:0;
      margin:2px 0 3px 0;
      position:relative;
    }

    &:hover {
      text-decoration:none;
    }

    &.active {
      background:url(../images/icon-close-01.png) no-repeat center center;
      opacity:1;

      span {
        display:none;
      }

    }

    @media (max-width: $screen-md-max3) {
      display: block;
    }

    @media (max-width: $screen-xs-max) {
      margin: 20px 0 -30px 0;
    }

  }

}
