body {
  font-family: 'proxima_nova_rgregular', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

a {
    color: $brand-primary;
    &:hover,
    &:active{
        color: darken($brand-primary, 10%);
    }
}
.container{
  max-width: 1170px;
}

/*pagination*/
.pagination {
  text-align: center;
  font-size: 13px;
}
.pagination a:hover {
  text-decoration: none;
}
.pagination .pages {
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
}
.pagination .current, .pagination a, .pagination .pages {
  padding: 5px 10px;
  background-color: $brand-secondary;
  color: #fff;
  display: inline-block;
  margin: 8px 5px;
  border: none;
}
.pagination .extend {
  padding: 5px;
  margin: 8px 0;
}
.pagination a:hover, .pagination .current {
  color: #fff;
  background-color: $brand-primary;
}

// content meta
.content-main {
  .meta-info {
    display: block;
    margin-bottom: 10px;
    font-size: 13px;
    span {
      font-weight: 600
    }
    time {
      display: inline-block
    }
    .author {
      display: inline-block;
      margin-left: 15px
    }
  }
}

// page/post nav
// page/post nav
.content-main {
  .page-nav {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea
  }
  .prv-link {
    width: 48%;
    float: left;
    display: block
  }
  .nxt-link {
    width: 48%;
    display: block;
    float: right;
    text-align: right;
  }
}

.social-icon-block {
  display: block;
  text-align: center;
  margin-top: 15px;
  line-height: 20px;
}

.social-icon-block ul  {
  padding-left: 0;
}
.social-icon-block ul li {
    display: inline-block;
}
.social-icon-block ul li a {
  font-size: 18px;
  padding: 5px 5px;
  color: $brand-primary;
  &:hover{
    color: darken($brand-primary, 15%)
  }
}
