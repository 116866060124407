@font-face {
  font-family: 'MinionProMedium';
  src: url('../fonts/MinionProMedium.eot');
  src: url('../fonts/MinionProMedium.eot') format('embedded-opentype'),
    url('../fonts/MinionProMedium.woff2') format('woff2'),
    url('../fonts/MinionProMedium.woff') format('woff'),
    url('../fonts/MinionProMedium.ttf') format('truetype'),
    url('../fonts/MinionProMedium.svg#MinionProMedium') format('svg');
   font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'allerbold';
    src: url('../fonts/aller_bd-webfont.eot');
    src: url('../fonts/aller_bd-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/aller_bd-webfont.woff') format('woff'),
         url('../fonts/aller_bd-webfont.ttf') format('truetype'),
         url('../fonts/aller_bd-webfont.svg#allerbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'fjordone';
  src: url('../fonts/fjordone-regular-webfont.eot');
  src: url('../fonts/fjordone-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fjordone-regular-webfont.woff') format('woff'),
    url('../fonts/fjordone-regular-webfont.ttf') format('truetype'),
    url('../fonts/fjordone-regular-webfont.svg#fjordone') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_rgregular';
  src: url('../fonts/proxima_nova_reg-webfont.eot');
  src: url('../fonts/proxima_nova_reg-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_reg-webfont.woff') format('woff'),
    url('../fonts/proxima_nova_reg-webfont.ttf') format('truetype'),
    url('../fonts/proxima_nova_reg-webfont.svg#proxima_nova_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_ltregular';
  src: url('../fonts/proxima_nova_light-webfont.eot');
  src: url('../fonts/proxima_nova_light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_light-webfont.woff') format('woff'),
    url('../fonts/proxima_nova_light-webfont.ttf') format('truetype'),
    url('../fonts/proxima_nova_light-webfont.svg#proxima_nova_ltregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_ltbold';
  src: url('../fonts/proximanova-semibold-webfont.eot');
  src: url('../fonts/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('../fonts/proximanova-semibold-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-semibold-webfont.svg#proxima_nova_ltbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_rgbold';
  src: url('../fonts/proxima_nova_bold-webfont.eot');
  src: url('../fonts/proxima_nova_bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proxima_nova_bold-webfont.woff') format('woff'),
    url('../fonts/proxima_nova_bold-webfont.ttf') format('truetype'),
    url('../fonts/proxima_nova_bold-webfont.svg#proxima_nova_rgbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
