/**
 * JHob Posts
 */
.single-job {
  .page-title {
    text-transform: uppercase;
  }

  .type-job {
    .meta-info {
      margin-bottom: 25px;
    }

    .job-desc {
      margin-bottom: 25px;
    }

    ul {
      list-style: none;
      padding-left: 1.3em;
      margin: 0;

      li {
        &:before {
          content: "-";
          display: inline-block;
          margin-left: -1.3em;
          /* same as padding-left set on li */
          width: 1.3em;
          /* same as padding-left set on li */
          padding-top: 3px;
          padding-bottom: 3px;

        }
      }
    }
  }



}
