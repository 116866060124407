// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}
.content-main {
  width: 100%;
  .sidebar-primary & {
    width: auto;
  }
}

.content-header {
  padding-left: 15px;
  padding-right: 15px;
}
.content-footer {
  padding-left: 15px;
  padding-right: 15px;
}

/**
 * BLog Posts content Block
 */

.blog-posts {
  margin-bottom: 30px;
  article {
    @include make-row();
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px dotted #dedede;
    h3 {
      margin-top: 0;
      margin-bottom: 3px;
      padding-bottom: 5px;
    }
    h2 {
      margin-bottom: 3px;
      margin-top: 0;
    }
    .blog-post-content {
      margin-bottom: 25px;
      @include make-sm-column(12);
      @include make-md-column(12);
      .meta-info {
        margin-bottom: 6px;
        line-height: 1.2;
        font-size: 13px;
        strong {
          font-family: 'proxima_nova_ltbold';
          font-weight: normal;
        }
      }
    }
    .blog-post-content-thumb {
      @include make-sm-column(4);
      @include make-md-column(3);
      margin-bottom: 10px;
      .testimonials & {
        @include make-sm-column(3);
        @include make-md-column(2);
        @include responsive-invisibility('.visible-xs');
        img {
          border-radius: 50%;
          border: 4px solid $brand-secondary;
        }
        a {
          &:hover {
            img {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  .has-thumb {
    .blog-post-content {
      @include make-sm-column(8);
      @include make-md-column(9);
      .testimonials & {
        @include make-sm-column(9);
        @include make-md-column(10);
      }
    }
  }
  .job-specs {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 1.5;
    display: block;
    span {
      margin-right: 10px;
      strong {
        font-family: 'proxima_nova_ltbold';
        font-weight: normal;
      }
    }
  }
  .job-description {
    line-height: 1.5;
    a.read-more {
    font-family: $font-family-prnov-bold;
    padding-left: 10px;
    }
  }
}
