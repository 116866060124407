/**
 * KMS live Streaming video
 */
.kms-live-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  embed,
  iframe,
  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .fluidvids {
    margin-bottom: -56.25%;
  }
}

.live-streaming-container {
  width: 69%!important;
  .floating-alert {
    position: fixed;
    top: 37px;
    z-index: 9999;
    background: rgba($brand-primary-lite, 1);
    color: $brand-white;
    margin-left: -35px;
    width: inherit;
    padding: 28px 35px 22px;
    border-radius: 0;

    p {
      color: $brand-white;
      font-size: 15px;
      margin-bottom: 5px;
    }

    .alert-content {
      display: block;
    }

    a {
      color: $brand-white;
      font-weight: bold;
      border-bottom: 1px dotted $brand-white;

      &.btn {
        font-family: $font-family-prnov-semibold;
        font-weight: normal;
        border-style: none;
        margin-bottom: 0;
      }

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid $brand-white;

        &.close {
          text-decoration: none;
          color: $brand-white;
        }
      }

      &.close {
        font-family: GothamMedium;
        font-size: 32px;
        opacity: 1;
        border-bottom: 0 dotted$brand-white;
      }
    }

    .alert-give-online {
      display: inline-block;
      float: right;
      padding-left: 15px;
      padding-right: 10px;
    }
  }
}

/**
 * fullscreen div button patch
 */
.media-img #fsBtn {
  position: absolute;
  left: -99999px;
}

/* overlay content*/
.live-streaming-block {
  position: relative;
  max-height: inherit;
  overflow: hidden;

  .live-video-wrapper {
    position: relative;

    .video-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: visible;
      width: 100%;

      img {
        width: auto;
        height: 100%;
        max-width: unset;
      }
    }

    .kms-live-video-wrapper {
      position: relative;
      max-width: 80%;
      margin: 0 auto;
      padding-bottom: 7%;
      padding-top: 4%;
    }
  }

  #overlay {
    position: absolute;
    top: 7%;
    right: 1%;
    color: $brand-white;
    text-align: right;
    width: 25%;
    z-index: 2147483647;
  }

  .overlay-content {
    background-color: rgba($brand-primary, 0.8);
    text-align: left;
    padding: 15px;
    font-size: 14px;
    float: right;
    color: $brand-white;

    h3 {
      color: $brand-white;
    }

    .su-button {
      margin-bottom: 10px;
      clear: both;
    }

    p {
      padding-top: 5px;
      color: $brand-white;
    }
  }

  .minimize-ico {
    background-color: rgba($brand-primary, 0.8);
    color: $brand-white;
    border: none;
    text-align: center;
    width: 40px;
    height: 40px;
    float: right;
    cursor: pointer;
    line-height: 40px;

    &:before {
      content: "-";
      font-size: 24px;
      font-weight: bold;
    }

    &.ico-plus {
      background-color: rgba($brand-primary-lite, 0.5);
      border: 1px solid $brand-primary-lite;

      &:hover {
        background-color: rgba($brand-primary-lite, 1);
      }

      &:before {
        content: "+";
        font-weight: bold;
        font-size: 24px;
      }
    }
  }

  #fsBtn {
    background-color: rgba($brand-primary-lite, 1);
    padding: 10px 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2147483647;
    border: 0;
    color: $brand-white;
    cursor: pointer;

    &:hover {
      background-color: rgba($brand-primary, 1);
    }

    /* Chrome only: */
    @media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
      display: none;
    }
  }
}
