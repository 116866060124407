.content-block {
  margin-bottom: 30px;
}

.view-all {
  float: right;
  font-size: 12px;
  color: $brand-primary;
  text-align: right;
  margin: 0px 15px 10px 0px;
  text-transform: uppercase;
  font-family: $font-family-prnov-semibold;
}


/*audio-library*/

.audio-library-block {
  h2 {
    font-size: 30px;
  }
  h4 {
    font-size: 19px;
  }
  .single-audio h2 {
    margin-bottom: 30px;
    padding: 0;
  }
  .audio-player {
    margin-right: 23px;
    width: 5%;
    .fa {
      font-size: 64px;
      color: #e3e3e3;
    }
    &:hover {
      cursor: pointer;
      @include transition (0.5s, ease);
      .fa {
        font-size: 64px;
        color: $brand-primary;
        @include transition (0.5s, ease);
      }
    }
  }
  .audio-details {
    width: 90%;
    float: left;
  }
  .audio-details h4 {
    padding-bottom: 3px;
    line-height: 15px;
  }
  .audio-details span {
    font-size: 14px;
    line-height: 16px;
  }
  .clip-description {
    font-size: 14px;
    line-height: 16px;
  }
  .download-link {
    background-color: #9ed9ab;
    color: #333;
    line-height: 34px;
    height: 33px;
    float: right;
    @include transition (0.5s, ease);
  }
  .libray-row {
    padding-bottom: 45px;
  }
}


/*Audio-widget*/

.audio-article {
  padding: 10px 0 20px;
  float: left;
  width: 100%;
  a {
    text-decoration: none;
    color: #D4D4D4;
    &:hover .fa {
      color: #008DA9;
    }
    &:hover h4 {
      color: #008DA9;
    }
  }
  aside {
    float: left;
    padding: 5px 0 0 60px;
    width: 100%;
    h4 {
      color: #333;
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 10px;
      font-family: $font-family-prnov-regular;
    }
    p {
      color: #333;
      font-size: 14px;
      letter-spacing: -0.02em;
      line-height: 18px;
      padding-bottom: 0;
    }
    span {
      display: block;
      float: left;
      margin-right: 5px;
      padding-right: 5px;
    }
  }
  .icon-bg {
    margin-right: 10px;
    display: block;
    color: #D4D4D4;
    float: left;
    font-size: 48px;
    height: 42px;
    line-height: 42px;
    margin-right: -42px;
    position: relative;
    width: 42px;
    .fa {
      font-size: 60px;
      color: #D4D4D4;
    }
  }
}


/*Recent images*/

.latest-gallery-block {
  .latest-pics {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      margin: 0px 1.5% 10px 0;
      border: 1px solid #f4f4f4;
      max-width: 120px;
      &:before {
        display: none;
      }
    }
  }
}

/*video-Library*/

.video-library-block {
  .video {
    padding: 15px 0 40px 0;
  }
  .video h2 {
    margin: 0;
    padding: 0;
  }
  .video form {
    text-align: right;
  }
  select {
    font-size: 16px;
    color: #555555;
    padding: 5px 20px 9px 10px;
    width: 56%;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }
  .library img {
    width: 100%;
  }
  .background-box {
    background: #058aab;
    padding: 13px 20px 2px 20px;
    min-height: 80px;
    margin-bottom: 30px;
  }
  .background-box h5 {
    margin: 0;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    padding-bottom: 7px;
  }
  .background-box p {
    margin: 0;
    color: #fff;
    font-size: 12px;
    padding: 0;
  }
  .background-box span {
    display: block;
  }
  .single-video img {
    width: 100%;
  }
}

/* image gallery block */
.image-gallery-block {
  .background-box {
    min-height: 65px;
  }
}

/*Latest-video*/

.latest-video {
  aside {
    padding: 20px 20px 20px 15px;
    background-color: #f4f4f4;
    width: 100%;
    p {
      line-height: 15px;
    }
    a {
      color: #058aab;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}


/*Featured video*/

.video-carousel-block {
  margin-bottom: 35px;
  .video-player-block {
    margin-bottom: 25px;
  }
  .video-re {
    padding-bottom: 0;
  }
  .featured-video img {
    width: 100%;
    margin-bottom: 44px;
  }
  .video-carosuel-block-top-right .btn-general {
    width: 100%;
    padding: 0px 25px;
  }
  .video-carousel-wrapper {
    overflow: hidden;
    background-color: #f5f2ee;
    padding: 20px 20px 0px 20px;
    .background-box2 {
      padding: 13px 9px 2px;
      min-height: 60px;
      background: #058aab none repeat scroll 0 0;
      margin-bottom: 30px;
      h5 {
        font-size: 14px;
        color: #fff;
      }
    }
    .video-imgbg {
      background-color: #000000;
      padding: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    img {
      width: 100%;
    }
    ul {
      overflow: hidden;
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      list-style: none;
      margin-right: 15px;
      &:before {
        display: none;
      }
    }
  }
}


/*grid-content*/

.content-grid {
  padding-bottom: 20px;
}

.grid-content h3 {
  padding-bottom: 3px;
  font-size: 21px;
}

.grid-content h3 a {
  font-size: 21px;
}

.grid-content p {
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 22px;
}

.grid-content a {
  font-size: 14px;
}

.grid-content a:hover {
  text-decoration: none;
}


/*event-grid*/

.events-row .grid-content {}

.events-row hr {
  margin: 5px 0 0 0;
}

.events-row {
  padding-bottom: 20px;
}

@media (max-width:1024px) {
  /*audio-library*/
  .audio-details {
    margin-left: 10px;
    width: 88%;
  }
  /*audio-widget*/
  .latest-audio-block aside {
    padding: 0px;
    width: 74%;
  }
  .icon-bg .fa {
    font-size: 40px;
  }
  .sidebar-block h4 {
    font-size: 15px;
  }
  /*featured video*/
  .video-carosuel-block-top-right .btn-general {
    width: 100%;
    padding: 0px 8px;
    font-size: 9px;
  }
  .feature-block-top-left p {
    font-size: 14px;
    line-height: 23px;
  }
  /*image rotator*/
  .slider-middle p {
    font-size: 17px;
    line-height: 21px;
  }
}

@media (max-width:800px) {
  /*audio-library*/
  .audio-details {
    width: 87%;
  }
  /*audio-widget*/
  .latest-audio-block aside {
    width: 66%;
  }
  .sidebar-block h4 {
    font-size: 12px;
  }
  .icon-bg .fa {
    font-size: 30px;
  }
  .background-box h5 {
    font-size: 11px;
  }
  /*event-list*/
  .sidebar-block .date {
    width: 58px;
    height: 58px;
    line-height: 20px;
    padding: 11px 0px 0px;
  }
  .sidebar-block .date span {
    font-size: 17px;
  }
  .sidebar-block p {
    font-size: 11px;
  }
  /*featured event*/
  .banner-inner figure img {
    margin: 0;
  }
  .featured-block-top-left {
    font-size: 12px;
  }
  .slider-middle {
    width: 81%;
  }
  /*image title*/
  .feature-box h2 {
    font-size: 23px;
  }
  /*latest video*/
  .latest-video a {
    font-size: 14px;
  }
}

@media (max-width:767px) {
  .container {
    padding: 0px 6px;
  }
  /*audio-library*/
  .libray-row .download-link {
    float: none;
    margin-top: 18px;
    margin-left: 43px;
  }
  .pagination li {
    font-size: 11px;
  }
  .pagination li a {
    padding: 10px 11px;
  }
  .audio-details {
    width: 80%;
  }
  .audio-details h4 {
    font-size: 12px;
  }
  /*audio-widget*/
  .latest-audio-block aside {
    padding: 0px;
    width: 73%;
  }
  .sidebar-block h4 {
    font-size: 12px;
  }
  /*event-list*/
  .sidebar-block aside {
    width: 86%;
  }
  .icon-bg .fa {
    font-size: 25px;
  }
  /*event-grid*/
  .grid-content h3 {
    font-size: 15px;
  }
  /*featured event*/
  .btn-general {
    width: 100%;
  }
  .slider-middle2 p {
    font-size: 15px;
  }
  .slider-middle {
    width: 65%;
  }
  /*image rotator*/
  .image-rotator-block .slider-middle {
    top: 10%;
    padding: 0px 30px 8px 20px;
  }
  .image-rotator-block .slider-middle p {
    font-size: 11px;
    line-height: 16px;
    padding: 0;
  }
  /*latest video*/
  .latest-video aside {
    width: 100%;
  }
  /*video Library*/
  .video form {
    text-align: left;
    margin-top: 15px;
  }
  .page {
    padding-left: 0;
    padding-right: 0;
  }
  .page li:first-child {
    padding: 1px 5px;
  }
  .pagination li a {
    padding: 1px 3px;
  }
  .audio-pagination li a {
    padding: 10px 13px;
  }
}


/**
 * Seperator Block
 */

.seperator {
  border-bottom: 5px solid #f1ede8;
  padding-top: 5px;
  margin-bottom: 25px
}


/**
 * image tile block
 */

.image-tile-block {
  opacity: 1;
  figure {
    text-align: center;
    img {
      width: 100%;
    }
  }
  aside {
    padding: 0 10px 8px;
    margin-top: -40px;
    h2 {
      font-family: 'proxima_nova_ltbold', Helvetica, Arial, sans-serif;
      padding-bottom: 12px;
    }
    p {
      padding-bottom: 0px;
      font-size: 16px;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    opacity: 0.9;
  }
}


/**
 * Daily word new design
 */

.daily-word-block {
  display: block;
  float: left;
  margin-top: 10px;
  margin-bottom: 30px;
  .dailyword-box {
    background: #fee8be;
    @include make-row();
    position: relative;
    .box-left {
      @include make-sm-column(4);
      @include make-xs-column(12);
      width: 100%;
      vertical-align: middle;
      background: url("../images/dw-bg.png") no-repeat scroll 0 0 / 768px 152px;
      height: 80px;
      display: table;
      .daily-word-title {
        vertical-align: middle;
        height: 100%;
        width: 100%;
        display: table-cell;
      }
      h1 {
        font-size: 38px;
        font-family: $font-family-allerbold;
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 0;
        font-weight: normal;
        line-height: 1.1;
      }
      @media only screen and (min-width : 768px) {
        background: url("../images/dw-bg.png") no-repeat scroll 0 0 / 550px 180px;
        height: 180px;
        h1 {
          font-size: 48px;
        }
      }
    }
    .box-right {
      @include make-sm-column(8);
      vertical-align: middle;
      padding: 30px 40px;
      font-size: 20px;
      .dw-more {
        font-family: $font-family-prnov-bold;
        font-size: 22px;
        display: block;
        float: right;
      }
    }
    .word-tag {
      float: left;
      background: #ffffff;
      position: absolute;
      top: 10px;
      left: 0;
      padding: 5px 7px;
      h4 {
        font-family:  $font-family-allerbold;
        letter-spacing: -1px;
        padding-bottom: 0;
        font-size: 15px;
        color: #018ba7;
      }
    }
  }
}
