/**
 * nav bar
 */
.navbar-default {
  background-color: $brand-secondary;
  border-color: $navbar-default-border;
  margin-bottom: 0;

  .navbar-nav {
    > li > a {
      color: #fff;

      &:hover,
      &:focus {
        color: #fff;
        background-color: darken($brand-primary, 10%);
      }
    }
    > .active > a {
      &,
      &:focus {
        background-color: $brand-primary;
        color: #fff;
      }
      &:hover{
        background-color: darken($brand-primary, 10%);
        color: #fff;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        color: $navbar-default-link-disabled-color;
        background-color: $navbar-default-link-disabled-bg;
      }
    }
  }
}

.navbar-default .navbar-toggle {
  position: relative;
  float: none;
  width: 100%;
  margin-right: 0;
  padding: 9px 30px 9px 15px;
  @include navbar-vertical-align(34px);
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 0px solid transparent;
  border-radius: 0px;
  text-transform: uppercase;
  color: #000;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus,
  &:hover {
    outline: 0;
    background: none;
    color: #fff;

  }
  // Bars
  i {
    float: right;
    line-height: 24px;
    font-size: 24px;
  }

  @media (min-width: $grid-float-breakpoint) {
    display: none;
  }
}


.dropdown:hover .dropdown-menu{
  display: block;
  background: $brand-secondary;
}

.dropdown-menu {
  border-radius: 0;
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  > li > a {
    color: #fff;
    padding: 6px 20px;
  }
}

@media only screen and (min-width : 768px) {
  .dropdown-menu {
    position: absolute;
    width: auto;
  }
}